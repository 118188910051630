import React from 'react'

// App components
import Layout from '../../components/layout'
import PageTitle from '../../components/PageTitle'
import ImageAndText from '../../components/ImageAndText'
import CustomLink from '../../components/CustomLink'

// App content
import collinsSoccer from '../../assets/collins-soccer.jpg'
import collinsFamily from '../../assets/dr-collins/collins-family.jpg'
import grant from '../../assets/dr-collins/DrCollins.jpg'
import TheBracesGuyLogo from '../../assets/the-braces-guy-logo.png'
import MeetDrCollinsContent from '../../content/meet-dr-collins'

// App utils
import config from '../../utils/config'
import { rhythm } from '../../utils/typography'
import { themeStyles, metrics, presets } from '../../utils/theme'

class Page extends React.Component {
  render() {
    const collinsIntro = MeetDrCollinsContent.COLLINS_INTRO
    const collinsIntroAdd = MeetDrCollinsContent.COLLINS_INTRO_ADD
    // const collinsOrthoTraining = MeetDrCollinsContent.COLLINS_ORTHO_TRAINING
    // const collinsOrthoTrainingAdd =
    //   MeetDrCollinsContent.COLLINS_ORTHO_TRAINING_ADD
    const collinsCommAndFamily = MeetDrCollinsContent.COLLINS_COMM_AND_FAMILY

    return (
      <Layout location={this.props.location}>
        <div css={themeStyles.contentWidth}>
          <PageTitle
            title={`Meet Dr. Collins - aka "The Braces Guy ®"`}
            bannerKey={'MEET_DR_COLLINS'}
          />
          <ImageAndText image={grant} backgroundSize="70%">
            <h2>Background, Education & Orthodontic Training</h2>
            <p>{collinsIntro}</p>
            <p>{collinsIntroAdd}</p>
          </ImageAndText>
          {/* <div css={[themeStyles.textPadding, themeStyles.elementMargin]}>
            <h2>Orthodontic Training</h2>
            <p>{collinsOrthoTraining}</p>
            <p>{collinsOrthoTrainingAdd}</p>
          </div> */}
          <div css={[themeStyles.textPadding, themeStyles.elementMargin]}>
            <h2>
              The Story Behind The Braces Guy ® (
              <CustomLink target="_blank" href="http://thebracesguy.com">
                @thebracesguy
              </CustomLink>
              )
            </h2>
            <p>
              Dr. Collins has become known across the world as The Braces Guy ®
              (
              <CustomLink target="_blank" href="http://thebracesguy.com">
                @thebracesguy
              </CustomLink>
              ), as he has positioned himself as the expert in providing
              educational and fun braces videos and content through various
              social media channels and online platforms. Via{' '}
              <CustomLink target="_blank" href={config.socialLinks.instagram}>
                Instagram
              </CustomLink>
              ,{' '}
              <CustomLink target="_blank" href={config.socialLinks.facebook}>
                Facebook
              </CustomLink>
              ,{' '}
              <CustomLink target="_blank" href={config.socialLinks.youtube}>
                YouTube
              </CustomLink>
              ,{' '}
              <CustomLink target="_blank" href={config.socialLinks.spotify}>
                Spotify
              </CustomLink>{' '}
              and{' '}
              <CustomLink target="_blank" href={config.socialLinks.tiktok}>
                TikTok
              </CustomLink>
              , The Braces Guy ® has over 5 million subscribers across all
              platforms. The Braces Guy ® has been a source for content in areas
              including:
            </p>
            <img
              src={TheBracesGuyLogo}
              css={{
                [presets.Tablet]: {
                  display: 'block',
                  float: 'right',
                  marginRight: metrics.defaultMargin,
                  maxHeight: '250px',
                },
                display: 'none',
                maxHeight: '200px',
                float: 'center',
                margin: '0 auto',
                marginBottom: metrics.defaultMargin,
                marginTop: rhythm(1),
              }}
            />
            <ul>
              <li>
                "How to" videos such as how to make retainers, place braces, and
                remove braces
              </li>
              <li>FAQ videos about braces, teeth whitening and invisalign</li>
              <li>Braces fashion videos</li>
              <li>Braces friendly recipe videos</li>
              <li>Braces colors inspo videos</li>
              <li>Before / After transformation photos and videos</li>
            </ul>
            <img
              src={TheBracesGuyLogo}
              css={{
                [presets.Tablet]: { display: 'none' },
                display: 'block',
                maxHeight: '200px',
                float: 'center',
                margin: '0 auto',
                marginBottom: metrics.defaultMargin,
                marginTop: rhythm(1),
              }}
            />
            <p>
              You can find{' '}
              <CustomLink target="_blank" href="http://thebracesguy.com">
                The Braces Guy ®
              </CustomLink>{' '}
              on the following channels:
            </p>
            <strong>Instagram:</strong>{' '}
            <CustomLink target="_blank" href={config.socialLinks.instagram}>
              Instagram.com/TheBracesGuy
            </CustomLink>
            <br />
            <strong>TikTok:</strong>{' '}
            <CustomLink target="_blank" href={config.socialLinks.tiktok}>
              TikTok.com/@thebracesuy
            </CustomLink>
            <br />
            <strong>YouTube:</strong>{' '}
            <CustomLink target="_blank" href={config.socialLinks.youtube}>
              CollinsVideos.com
            </CustomLink>
            <br />
            <strong>Facebook:</strong>{' '}
            <CustomLink target="_blank" href={config.socialLinks.facebook}>
              Facebook.com/RochesterOrthodontics
            </CustomLink>
            <br />
            <strong>Spotify:</strong>{' '}
            <CustomLink target="_blank" href={config.socialLinks.spotify}>
              CollinsMixedTape.com
            </CustomLink>
          </div>

          <ImageAndText image={collinsFamily} isSquare focus="60% 30%">
            <h2>Community & Family</h2>
            <p>{collinsCommAndFamily}</p>
          </ImageAndText>
          <div css={themeStyles.textPadding}>
            <h2 css={{ marginTop: metrics.defaultMargin }}>
              Professional Affiliations
            </h2>
            <ul>
              <li>American Association Of Orthodontists</li>
              <li>Minnesota Dental Association</li>
              <li>Midwest Society Of Orthodontists</li>
              <li>Zumbro Valley Dental Society</li>
              <li>
                Beta Beta Chapter Of Omicron Kappa Upsilon, National Dental
                Honor Society
              </li>
              <li>Phi Kappa Phi</li>
              <li>Diplomate Of The American Board Of Orthodontists (ABO)</li>
              <li>Phi Beta Kappa</li>
            </ul>
            <img
              src={collinsSoccer}
              css={{
                display: 'block',
                width: '500px',
                maxWith: '500px',
                margin: '0 auto',
                marginTop: metrics.defaultMargin,
                marginBottom: metrics.defaultMargin,
              }}
            />
            <p css={{ textAlign: 'center' }}>
              Dr. Collins enjoys coaching soccer, and has volunteered as a coach
              for the Mayo High School girls varsity soccer team.
            </p>
          </div>
        </div>
      </Layout>
    )
  }
}

export default Page
