const MeetDrCollinsContent = {
  COLLINS_INTRO: `Meet Dr. Grant Collins: a proud Minnesotan who's always been happiest perfecting smiles right in his own backyard. Growing up in Rochester, he ventured from Century High School to St. Olaf College, then on to the University of Minnesota School of Dentistry, all the while cultivating a passion for helping others show off their grins. In addition to being selected as a member of Phi Beta Kappa, Dr. Collins graduated Summa Cum Laude in the top 3% of his class in both college and dental school, he received numerous awards for scholarship and clinical ability, including the prestigious Betzner Scholarship, an award given for having "technical skill above and beyond his peers."`,
  COLLINS_INTRO_ADD: `In 2010, he was grateful to become the only dentist accepted into Mayo Clinic's orthodontic residency program that year—an opportunity that fueled his commitment to innovation. His cutting-edge research on safer imaging techniques for kids and patients landed a first place award by the American Association of Orthodontists among top orthodontic residents from all over the country.`,
  COLLINS_ORTHO_TRAINING: `In 2010, Dr. Collins was honored to be the only dentist accepted
to Mayo Clinic for his orthodontic training amongst hundreds of
top dental school applicants nationwide. He completed the
three-year orthodontic residency at Mayo Clinic, where he had the
opportunity to further develop his passion for orthodontics. At
the American Association of Orthodontists Annual Meeting, Dr.
Collins received the 1st place AAO Charley Schultz Award among
research participants from all over the world for his cutting edge
research on the use of new imaging techniques in orthodontics that
are safer for children and patients.`,
  COLLINS_ORTHO_TRAINING_ADD: `He has also taken the extra step to become a board-certified
orthodontist as a Diplomate of the American Board of Orthodontics.
Only about 25% of practicing orthodontists nationwide have
achieved the status of becoming “board-certified” and Dr. Collins
did so within one year of completing his orthodontic training at
Mayo Clinic.`,
  COLLINS_COMM_AND_FAMILY: `Dr. Collins has many interests outside of creating beautiful
smiles, including playing the guitar, soccer, traveling, hiking
and spending time with children Caden, Grayson and Lennon. In addition to coaching and spending time with
his family, Dr. Collins also enjoys attending all types of
community activities of his patients including theater, dance,
music, and sporting events. It is important to him not only to
provide his patients with beautiful and healthy smiles, but also
to support them within the community.`,
}

export default MeetDrCollinsContent
